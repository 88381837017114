@import "countries.css";
@import "highlight.css";
@import "quality.css";
@import "section.css";
@import "services.css";
@import "testimonial.css";
@import "registration.css";
@import "footer.css";
@import 'terms-and-condition.css';

html {
  font-size: 62.5%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0rem;
  padding: 0rem;
}

h1 {
  font-size: 50px;
}

.section-start-end {
  padding: 7rem 0rem 10rem 0rem;
}

.bg-color-blue {
  background-color: #176299;
}

.bg-color-white {
  background-color: #ffffff;
}

.text-color-white {
  color: white !important;
}

.text-color-off-white {
  color: #dedede !important;
}

.testimonial-section-start {
  padding: 0rem 0rem 10rem 0rem;
}

.header0 .home-page {
  padding: 0 24px 0 40px !important;
}

.home-page-wrapper {
  background-color: white !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 100 !important;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
}