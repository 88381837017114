.service-card .ant-card-meta-description {
  color: #888888;

  line-height: 3ex;
  height: 15ex;
  overflow: unset;
  display: unset;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
}
.service-card .ant-card-meta-title {
  white-space: unset;
}

.service-card img {
  width: 100%;
  height: 14rem;
  padding: 4rem 0 0 0;
}
.service-card {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-bottom: 108px;
  height: 100%;
}
.services-text .ant-card-meta-title {
  color: #176299;
  font-size: 2rem;
  font-weight: bold;
}

.services-text .ant-card-meta-description {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.03rem;
  margin-bottom: 2rem;
  color: darkgray;
}
.service-card .card-footer {
  margin-top: auto;
}
.service-card .ant-card-meta-description {
  line-height: 3ex;
  height: unset;
  /* overflow: hidden; */
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 5; */
  /* -webkit-box-orient: vertical; */
}
