.registration-form-tag.ant-tag-checkable {
  border-color: #176299;
  border-radius: 25px;
  font-size: 13px;
  color: #176299;
  padding: 2px 10px;
  margin-top: 0.5rem;
}

.registration-page-background {
  background-color: #176299;
  padding: 0px;
  margin: 0px;
}

.registration-ant-form {
  padding: 3rem 5rem 0 5rem;
}

@media only screen and (max-width: 600px) {
  .registration-ant-form {
    padding: 3rem 3rem 0 3rem;
  }
}



.register-input {
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 0.1rem solid #176299 !important;
}

.register-select .ant-select-selection {
  border: none;
  border-radius: 0 !important;
  border-bottom: 0.1rem solid #176299 !important;
}

@media only screen and (max-width: 600px) {
  .register-form-column {
    padding: 1rem !important;
  }
}

.register-form-column {
  padding: 7rem 10rem 10rem 10rem;
}

.register-text {
  background-color: #176299;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 76% 100%, 0% 82%);
}

@media only screen and (max-width: 600px) {
  .register-text {
    clip-path: unset !important;
  }
}

@media only screen and (max-width: 600px) {
  .register-text__glob-header-img {
    display: none;
  }
}

.register-text p {
  padding: 2rem 0;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  color: #176299;
}

.register-text .ant-form label {
  font-size: 1.7rem !important;
  color: #176299;
  font-weight: 600;
}

.register-text .ant-form-item-label {
  line-height: 16px;
  padding: 0rem;
}

.register-checkbox span {
  font-size: 1.5rem !important;
  font-weight: normal;
  color: #818080;
}

.register-form {
  text-align: center;
  border: none;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);

  padding: 0 !important;

  background-color: #ffffff !important;
  border-radius: 0.5rem;
}

.register-form .ant-tag-checkable-checked {
  background-color: #176299;
  color: white !important;
}

.register-form .ant-btn-primary {
  background-color: #00a0e3;
  border: 0.2rem solid #00a0e3;
  border-radius: 0 !important;
  height: 5rem;
}