.section-header {
  font-size: 4rem;
  color: #1c1c1c;
  font-weight: bold;
  margin: 0rem 0rem 3rem 0rem;
  line-height: 6rem;
}

.section-para {
  font-size: 1.7rem;
  color: #888888;
  margin-bottom: 6rem;
  text-align: center;
}
