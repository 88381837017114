.terms-condition-heading {
  color: rgb(23, 98, 153);
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}

.heading-para {
  padding: 5rem 11rem;
  color: rgb(112, 112, 112);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
}

@media only screen and (max-width: 600px) {
  .heading-para {
    padding: 5rem 3rem;
  }
}

.paragraph-cell {
  padding: 0rem 20rem;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .paragraph-cell {
    padding: 0rem 3rem;
  }
}


.section-heading {
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #176299;
  margin-bottom: 2rem;
}

.question-heading {
  margin-top: 2rem;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #176299;
}

.para {
  margin: 1rem 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.list-style {
  list-style-type: disc;
  list-style-position: inside;
  margin: 1rem 0 1rem 1rem;
}

.nested-list {
  list-style-type: circle;
  list-style-position: inside;
  margin: 1rem 0 1rem 3rem;
}