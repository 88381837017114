.quality-image {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.quality-points {
  text-align: justify;
}
.quality-image img {
  margin-bottom: 7rem;
}
