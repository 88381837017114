.highlight-card {
  margin-top: 40px;
}

.row-highlight {
  margin-bottom: 0px;
  padding-bottom: 100px;
}
.highlight-card .ant-card {
  height: 100%;
  border: 3px solid #176299;
  border-radius: 4px;
  background-color: #176299;
}
.highlight-card h2 {
  color: #ffffff;
  margin-bottom: 2rem;
}
.highlight-card p {
  color: #ffffff;
  margin-bottom: 2rem;
}

.highlight-card img {
  margin-bottom: 2rem;
  width: 6rem;
  height: 6rem;
}
.highlight-card .ant-card-body {
  padding: 1.2rem;
}
